

.App {
  text-align: center;
   background-color: #EDE7D9
}


.nav{
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex; 

}
.nav-item{
 width: 80%;
  height: 10vh;
  text-align: center;
  margin: 5vh 0 
}

.test{
width: 10vw;
transition: width 2s;
}

.line{
  height: 2px;
  width: 20px;
  background: #EFF1ED;
  transition: all 0.2s ease;
  transform: none
}
.ham-container{
  height: 32px;
  width: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 4px;

}
.ham-container > div:first-child {
  margin-bottom: 5px;
}

.ham-container > div:last-child {
  margin-top: 5px;
}

.lineTop {
  transform:  rotate(45deg) ;
  transform-origin: top left;
}

.lineMiddle {
  opacity: 0; 
  transform: translateX(-16px) 
}

.lineBottom {
  transform: translateX(-1px) rotate(-45deg) ;
  transform-origin: top left;

}
.menu{
  position: fixed;
  top: 5vh;
  left: 3vw;
 background-color: #0F7173;
 border-radius: 50%;
 height: 40px;
 width: 40px;
 display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 1s ease;
  z-index: 20000;
  box-shadow: 2px 2px 5px black;
}
.menu-open{
  position: fixed;
  top: 5vh;
  left: 3vw;
 background-color: #0F7173;
  height: 90vh;
  width: 30vw;
  border-radius: none;
  transition: all 1s ease;
  z-index: 20000;
  box-shadow: 2px 2px 5px black;
}
h2{
  font-family: "IM Fell English SC", serif; font-size: 32px; color: #0D1B1E;
}
h1{
  font-family: "IM Fell English SC", serif; font-size: 40px; color: #0D1B1E;
}
.white-bar{
  border: 1px solid rgb(239, 241, 237);
  margin: 10px
}
.green-bar{
  border: 1px solid #0F7173 
}
p{
  font-family: "Josefin Sans", sans-serif; color: rgb(13, 27, 30);
}
.white{
  color:  rgb(239, 241, 237);
}
.header{
  width: 100vw; height: 100vh; background-size: 100vw; background-position: 50% 25%; background-image: url("https://res.cloudinary.com/neversettels/image/upload/v1565640106/SchweitzerPics/P1020641_qsjx0l.jpg"); color: rgb(79, 82, 87); align-items: center; display: flex; justify-content: center; flex-direction: column; margin-bottom: 0px; box-shadow: rgb(0, 0, 0) 0px -10px 10px -10px inset;
}
.logo{
  height: 250px; width: 250px; border-radius: 50%; background-color: rgba(239,241,237, 0.5); display: "flex"; justify-content: space-evenly; align-items: center
}
.logo>img{
  width: 200px

}

 .about{
   height: auto;
  display: flex; justify-content: space-evenly; padding: 20vh 0; align-items: center; 
 }
 .about-img{
  height: 70vh
 }
 .info{
  display: flex; flex-direction: column; justify-content: center; align-items: center; background-color: rgb(79, 93, 117); height: 160vh; box-shadow: rgb(0, 0, 0) 0px 0px 19px 5px; margin-bottom: 0vh;
 }

 .info-container{
  display: flex; padding: 10px; justify-content: space-evenly; width: 82vw;
 }

 .info-text{
  width: 40vw; display: flex; flex-direction: column; justify-content: center; align-items: center;
 }
 .width{
   width: 30vw
 }
 .icon-box{
  font-family: "Josefin Sans", sans-serif; display: flex; justify-content: space-evenly; flex-wrap: wrap; color: rgb(239, 241, 237);
 }
 .button-contact{
  background: none; border: 2px solid rgb(239, 241, 237); font-family: "Josefin Sans", sans-serif; color: rgb(239, 241, 237); cursor: pointer; padding: 10px;
 }
 .sm-icon{
  font-family: "IM Fell English SC", serif; font-size: 64px;
 }
 .carousel-width{
   width: 40vw;
 }

 .activity-grid{
  display: flex; flex-wrap: wrap; width: 40vw; padding: 15px; justify-content: space-evenly; font-family: "Josefin Sans", sans-serif; color: rgb(13, 27, 30);
 }
 .activity-item{
  width: 12vw;
 }
 .contact-banner{
  display: flex;
   justify-content: center;
   width: 100vw;
   height: auto;
    background-size: 100vw;
     background-position: 50% 40%;
      padding: 5vh;
       box-shadow: inset 0 -10px 10px -10px #000000;
        background-image: url( 'https://res.cloudinary.com/neversettels/image/upload/v1569375377/SchweitzerPics/IMG_3428_g4dfzb.jpg' )
}
.banner-content{
  width: 60vw;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  color:rgb(239, 241, 237);
  flex-direction: column;
   margin: 0;
   padding: 5vh
}

.form-container{
  display: flex; padding: 10px; justify-content: space-evenly; width: 100vw;
}
.contact-item{
  width: 40vw;
}
footer{
  height: 10vh;
  background-color:  rgb(79, 93, 117);
  color: #9FA6B3;
  display: flex;
  justify-content: center;
  flex-flow: wrap row;
  align-items: center;
}
footer a{
  color: #9FA6B3;
  text-decoration: underline
}
footer span{
  margin: 10px
}
.icon{
  font-size: 20px;
  color: black;
  transition: all 0.5s ease;

}
.icon:hover{
  color:#0F7173;
  font-size: 60px;
  transition: all 0.5s ease;

}
.activity-grid>a{
  margin: 10px
}
 @media only screen and (max-width: 415px) {
  .menu-open{
    width: 94vw;
    height: 90vh
  }

  .header{
    background-size: cover;
    width: auto;
    background-repeat: no-repeat
  }
  .about{
    flex-direction: column; 
  }
  .width{
    width: 90vw
 }
 .about-img{
  height: 40vh
 }
 .info{
  height: auto;
 }
 .info-container{
   flex-direction: column;
   width: 100vw;
   justify-content: center; 
   align-items: center;
   

 }
 .info-text{
   margin-bottom: 2vh
 }
 .carousel-width{
  width: 90vw;
}
.activity-grid{
  width: 80vw
}
.activity-item{
  width: 30vw;
  padding: 0;
  margin: 2vh 0
 }
 .contact-banner{
   width: auto;
   background-size: cover;
   background-repeat: no-repeat
 }
 .form-container{
   align-items: center;
   justify-content: center;
   flex-direction: column-reverse;
 }
 .contact-item{
  width: 80vw
}
footer{
  height: auto;
  padding: 10px;
text-align: center
}
}
